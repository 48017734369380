@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
  /* background-image: url(../public/bg-blur.svg); */
  background: linear-gradient(90deg, rgb(29 66 101) 30%, rgba(186, 217, 247, 1) 100%, rgba(186, 217, 247, 1) 100%);
  background-position: right;
  background-size: cover;
  font-family: "Montserrat", sans-serif;
}
/* ----------------- LOGIN ---------------------------- */

.login-container input:focus {
    outline: 0;
    box-shadow: none;
    border-color: #E3F4F6;
}

.login-container label {
  font-family: "Montserrat", sans-serif;
  padding-bottom: 8px;
}

.login-container  button.btn.btn-primary {
  background: #42B4C5;
  border: 1px solid #E3F4F6;
  padding: 15px;
  font-weight: bold;
}

.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
  position: relative;
}

.appl_title{
  position: absolute;
  top: 106px;
  right: 84px;
  color: #01a5bd;
  font-weight: bold;
  font-size: 29px;
  line-height: 1.3;
}

.login-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

.login-container .form-group {
  margin-bottom: 15px;
}

.login-container .btn {
  width: 100%;
}


.login_logo{
  width: 93%;
  margin-bottom: 21px;
}
/* ------------------------ */



.custom_menu_icon {
  border: 0 !important;
  background: transparent !important;
}

.custom_menu_icon img {
  width: 45px;
}

.modal-open .custom_menu_icon img {
  filter: blur(1px);
}

/* .offcanvas.offcanvas-start {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  width: 450px !important;
  transform: translate(-50%, -32%) !important;
  min-height: 65vh !important;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1050;
  background: radial-gradient(34.64% 47.31% at 85.33% 9.72%, rgba(186, 217, 247, 0.35) 30%, rgb(50 102 153 / 35%) 100%), radial-gradient(64.19% 119.73% at 4.14% 42.53%, rgba(186, 217, 247, 0.35) 24.5%, rgba(0, 38, 74, 0.35) 63%), radial-gradient(45.27% 84.44% at 76.41% 97.68%, rgba(186, 217, 247, 0.35) 0%, rgba(0, 38, 74, 0.35) 84%);
} */

.offcanvas.offcanvas-start {
  background: radial-gradient(34.64% 47.31% at 85.33% 9.72%, #bad9f759 30%, #32669959 100%), radial-gradient(64.19% 119.73% at 4.14% 42.53%, #bad9f759 24.5%, #00264a59 63%), radial-gradient(45.27% 84.44% at 76.41% 97.68%, #bad9f759 0, #00264a59 84%);
  border-radius: 8px;
  box-shadow: 0 0 10px #0000001a;
  left: 50% !important;
  /* min-height: 65vh !important; */
  position: fixed !important;
  top: 35% !important;
  transform: translate(-50%, -13%) !important;
  width: 450px !important;
  z-index: 1050;
}

.offcanvas.offcanvas-start * {color: #fff !important;}

.btn-close{
  background: #fcfafa var(--bs-btn-close-bg) center / 1em auto no-repeat;
}

.offcanvas.offcanvas-start.offcanvas-body {
  overflow-y: auto !important;
}

.offcanvas.offcanvas-start .offcanvas-header {
  justify-content: flex-end !important;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px; 
  background-color: #f4f4f4; 
}
.text-loading{
    color: #01a5bd;
    font-size: 1.5rem;
    margin-right: 10px;
 
}
/* ------------------------------------------------- */
.full-screen-slider img {
  object-fit:contain;
}
.im_item{width: 100%;}
.carousel-caption {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.3); /* Optional dark overlay */
  color: white;
  font-size: 24px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
  transition: opacity 0.5s ease-in-out;
}

.slide-number-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 5px;
}

.carousel-fade .carousel-item {
  transition: opacity 1s ease-in-out;
}

.carousel-item img {
  transition: transform 1s ease-in-out;
}

.carousel-item.active img {
  transform: scale(1.05); /* Slight zoom effect */
}


/* ------------- Custom Navbar ----------------- */

.backtohome_btn {
  display: block;
  text-decoration: none;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 13px 22px;
  background: #ECF8F9;
  color: #42B4C5;
  font-size: 16px;
}
.backtohome_btn i{padding-inline-end: 4px;}


nav.navbar{
  align-items: center;
  background: #fff;
  border: 1px solid #ecf8f9;
  border-radius: 16px;
  display: flex;
  /* min-height: 32px; */
  justify-content: space-between;
  margin-bottom: 105px;
  margin-top: 26px;
  padding: 20px 54px;
}

.cus_navbar {
  display: flex;
  padding: 0px 54px;
  justify-content: space-between;
  /* background: #fff;
  border: 1px solid #ECF8F9; */
  border-radius: 16px;
  height: 32px;
  margin-bottom: 105px;
  align-items: center;
  margin-top: 26px;
  flex-direction: column;
}
.cus_navbar ul li {
  list-style-type: none;
  margin-bottom: 40px;
}

.cus_navbar ul li .nav-item {
  display: flex;
  text-decoration: none;
  align-items: flex-end;
}
.cus_navbar ul {
  display: flex;
  margin-bottom: 0;
  flex-direction: column;
  margin-top: 24px;
  align-items: flex-start;
  padding-left: 0;
  margin-left: 0;
}
.cus_navbar ul li h4 {
  color: #151A20;
  font-size: 18px;
}
.cus_navbar .icon {
  width: 32px;
  height: 32px;
  margin-inline-end: 12px;
}
/* ------------------------ */


/* ------------- Mode ----------------- */
.select_mode .icon {
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
}

.select_mode h4 {
  color: #090B0E;
  font-size: 18px;
  font-weight: 500;
}

.item_wrap {
  border: 1px solid #42B4C5;
  box-shadow: 0px 24px 24px 0px #9F9F9F1A;
  align-items: center;
  /* min-height: 275px; */
  justify-content: center;
  padding: 20px 0;
}

.select_mode ul li {
  list-style-type: none;
  display: flex;
  align-items: self-start;
}

.select_mode ul {
  margin: 0;
  left: 0;
}

.select_mode .left_wrap h4 {
  font-size: 18px;
  font-weight: 500;
  color: #090B0E;
}

.select_mode .left_wrap {
  text-align: center;
}

.select_mode ul li i {
  color: #42B4C5;
  font-size: 22px;
  margin-bottom: 8px;
  display: block;
}

h5.coming_soon {
  color: #FF1F00;
  margin-left: 41px;
  font-weight: 700;
  font-size: 18px;
}


.select_mode ul li, .select_mode h4 {
  color: #fff !important;
}


/* ------------------------ */


.clickable-item{    cursor: pointer;}

/* ------------- HOME ----------------- */
.home {
  padding-top: 48px;
}
.home h2 {
  margin-bottom: 28px;
  margin-left: 95px;
  font-weight: 700;
  font-size: 32px;
}
.home-item {
  text-align: center;
  border: 1px solid #e3f4f6;
  display: block;
  max-width: 440px;
  margin: auto;
  padding: 30px;
  text-decoration: none;
  transition: all .3s linear;
  background: #fff;
  border-radius: 12px;
}

.home-item:hover{
  border: 1px solid #b9e7ec;
}
.home-item:hover h4 {
color: #42B4C5;
}
.home-item .icon {
  width: 100px;
  height: 100px;
  margin: auto;
  display: block;
  margin-bottom: 12px;
}
.home-item h4 {
  font-size: 28px;
  color: #151A20;
  font-weight: 700;
  padding-top: 10px;
}



/* ------------------------ */

.mode {
  border: 1px solid #eeeeee;
  color: #000;
  padding: 15px;
}

.side_box h4 {
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;
}

.mode h5 {
  text-align: center;
}

.mode_side{
  border: 1px solid #eeeeee;
  margin:20px 10px;
  border-radius:20px;
  padding-top:14px;
  padding-left:0px;
  margin-left:0px;
  margin-right:0;
  /* margin:0 auto */
  /* padding-right:10px */
}
.mode ul {height:220px}

.mode ul li {
  font-size: 14px;
}

/* .table>:not(caption)>*>*{
  background-color: #eeeeee !important;
} */


.commands-section {
  /* background: #eeeeee; */
  text-align: center;
}

.nav-tabs .nav-link.active {
  background: #42B4C5;
  color: #fff;
}

.nav-tabs .nav-link {
  color: #31d2f2;
}

thead tr th{
  color: #42B4C5 !important;
}



tbody tr td {
  background-color: #fff !important;
}





/* ---------------- Dropdown----------- */

.chart_temp {
  padding: 20px;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  /* max-width: 600px; */
  margin: auto;
  background: #fff;
  margin-top: 15px;
}

.text-info {
  color: #007bff;
  font-size: 1.5rem;
  margin-bottom: 15px;
}
.dropdown-container .text-info{display: inline-block;}
.dropdown-container {
  margin-bottom: 20px;
  text-align: center;
}

.form-check.form-switch label {
  color: #fff;
}

.dropdown-label {
  font-size: 1rem;
  margin-bottom: 5px;
  /* display: block; */
  color: #2c2c2c;
  display: inline-block;
  margin-inline-start: 10px;
  margin-inline-end: 5px;
}

.dropdown-select {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
  background-color: #fff;
  cursor: pointer;
}

.dropdown-select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}


.system_info {
  padding-bottom: 56px;
}